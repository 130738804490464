export default function initializeUnassignedLogsTable() {
  const tableOptions = {
    headings: {
      'checkbox': '',
      'date': 'Date',
      'employee': 'Employee',
      'issue': 'Issue',
      'summary': 'Summary',
      'comment': 'Comment',
      'hours': 'Hours',
      'ratio': 'Ratio'
    },
    editable: {
      'checkbox': false,
      'date': false,
      'employee': true,
      'issue': false,
      'summary': false,
      'comment': true,
      'hours': false,
      'ratio': true,
    },
    sortable: {
      'checkbox': false,
      'date': false,
      'employee': false,
      'issue': false,
      'summary': false,
      'comment': false,
      'hours': false,
      'ratio': false,
    },
  };

  return {tableOptions};
}