<template>
  <div>
    <CustomTable :options="tableOptions"
                 :table-data="logs"
                 :selected-rows="checkboxController.selectedRows"
                 :bordered-cell="editableCellHandler.cell"
                 class="logs-table">
      <!-- Head -->
      <template #headcheckbox>
        <BasicCheckbox id="checkbox-head"
                       :is-checkbox-checked="checkboxController.isCheckboxAllChecked(logs)"
                       :callback="() => selectAllRows()"/>
      </template>

      <!-- Data -->
      <template #checkbox="props">
        <BasicCheckbox :id="`checkbox-${props.rowData.id}`"
                       :is-checkbox-checked="checkboxController.isCheckboxChecked(props.rowData.id)"
                       :callback="() => selectRow(props.rowData)"/>
      </template>
      <template #date="props">
        {{ props.rowData.date }}
      </template>
      <template #employee="props">
        <EmployeeEditableCell v-if="props.rowData.employee.displayName"
                              @save="({employeeId}) => editEmployee(employeeId, props.rowData)"
                              @on-active-edit="updateActiveEditableCell"
                              @on-inactive-edit="updateActiveEditableCell"
                              :row-id="props.rowData.id"
                              :text="props.rowData.employee.displayName"
                              :content-id="`name-${props.rowData.employee.spacelessDisplayName}`"/>
      </template>
      <template #issue="props">
        <!-- If the link is not present, the issue key is a simple text. -->
        <DLink v-if="props.rowData.issueLink" :text="props.rowData.issue.key" :link="props.rowData.issueLink"/>

        <span v-else>{{ props.rowData.issueKey }}</span>
      </template>
      <template #summary="props">
        {{ props.rowData.issue.summary }}
      </template>
      <template #comment="props">
        <EditableTableCell v-if="editable"
                           @save="({content}) => editComment(content, props.rowData)"
                           @on-active-edit="updateActiveEditableCell"
                           @on-inactive-edit="updateActiveEditableCell"
                           cell-key="comment"
                           :row-data="props.rowData"
                           :content-id="`comment-${props.rowData.id}`"/>
        <span v-else>{{ props.rowData.comment }}</span>
      </template>
      <template #hours="props">
        {{ props.rowData.hours }}
      </template>
      <template #ratio="props">
        <div class="ratio-cell">
          <EditableTableCell v-if="editable"
                             @save="({content}) => saveEnteredRatio(content, props.rowData)"
                             @on-active-edit="updateActiveEditableCell"
                             @on-inactive-edit="updateActiveEditableCell"
                             cell-key="ratio"
                             :row-data="props.rowData"
                             :content-id="`ratio-${props.rowData.id}`"
                             class="editable-cell-ratio"/>
          <span v-else>{{ props.rowData.ratio }}</span>
          <ReasonTooltip :log="props.rowData" @specify-reason="() => specifyReason(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <!-- Specify reason for ratio change -->
    <SpecifyReason v-if="isSpecifyReasonModalDisplayed"
                   @reason-confirmed="onReasonConfirmed"
                   :type-of-change="typeOfChange"
                   :new-value="newValueOfChange"
                   :logs="[activeRow]"/>
  </div>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import CustomTable from "@/components/partials/table/CustomTable";
import BasicCheckbox from "@/components/elements/BasicCheckbox";
import CheckboxController from "@/utils/controllers/CheckboxController";
import EditableTableCell from "@/components/partials/table/EditableTableCell";
import EditableRatioCell from "../table/ratio/EditableRatioCell";
import SearchInputField from "@/components/partials/search/SearchInputField";
import {EDIT_LOG} from "@/store/log/actions";
import EmployeeEditableCell from "@/components/partials/table/EmployeeEditableCell";
import Toast from "@/components/elements/Toast";
import {EditableCellHandler} from "@/utils/helpers/editableCellHelpers";
import SpecifyReason from "@/components/partials/reasons/SpecifyReason";
import {MODAL_NAMES} from "@/models/ModalContent";
import {TYPE_OF_CHANGE} from "@/models/reasons/Reason";
import {isChange, REASONS_MODAL_IDS, reasonSetup} from "@/utils/helpers/reasonsHelper";
import {initializeToast, TOAST_TEXTS} from "@/utils/helpers/toastHelpers";
import ReasonTooltip from "@/components/partials/reasons/ReasonTooltip";
import {getModalHelpers} from "../../../composables/modalHelper";
import initializeUnassignedLogsTable from "@/composables/unassignedLogs/getUnassignedLogsTable";
import DLink from "@/components/elements/basicUI/DLink";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";

export default {
  name: "LogsTable",
  components: {
    DLink,
    ReasonTooltip,
    SpecifyReason,
    Toast,
    EmployeeEditableCell,
    SearchInputField,
    EditableRatioCell,
    EditableTableCell,
    BasicCheckbox,
    CustomTable
  },
  props: {
    logs: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['selected-rows', 'refresh-table-data'],
  setup(props, {emit, root}) {
    const store = root.$store;

    /** Table **/
    const {tableOptions} = initializeUnassignedLogsTable();

    /** Emits **/
    const refreshTableData = () => emit('refresh-table-data');

    /** Checkboxes **/
    const checkboxController = ref(new CheckboxController());

    function selectRow(rowData) {
      checkboxController.value.selectRow(rowData);
      updateParent();
    }

    function selectAllRows() {
      checkboxController.value.selectAllRows(props.logs);
      updateParent();
    }

    function updateParent() {
      emit('selected-rows', checkboxController.value.selectedRows);
    }

    /** Edit comment **/
    /** TODO: Editing works pretty much the same for each one. Merged them into 1? **/
    function editComment(content, row) {
      // Update the comment of the row with the new comment.
      row.comment = content;

      // Edit the log based on the new row data.
      editLog(row).then(response => {
        if (response) {
          refreshTableData();
          ToastHandler.addNew(TOAST_CONTENTS.COMMENT_EDITED);
          resetActiveRow();
        }
      })
    }

    /** Edit employee **/
    function editEmployee(employeeId, row) {
      // Set the new employee id.
      row.setEmployeeId(employeeId);

      // Edit the log based on the new row data.
      editLog(row).then(response => {
        if (response) {
          refreshTableData();
          ToastHandler.addNew(TOAST_CONTENTS.EMPLOYEE_EDITED);
          resetActiveRow();
        }
      })
    }

    /** Edit ratio **/
    function saveEnteredRatio(ratio, row) {
      saveValueAndSpecifyReason(Number(ratio), row, TYPE_OF_CHANGE.RATIO);
      displaySpecifyReasonModal(null, () => editRatio(ratio, row), REASONS_MODAL_IDS.LOGS_TABLE); // Display the specify reason modal.
    }

    function editRatio(content, row, reason = "") {
      // Set the new ratio.
      row.ratio = Number(content);

      // Set the reason.
      row.excludeReason = reason;

      // Edit the log based on the new row data.
      editLog(row).then(response => {
        if (response) {
          refreshTableData();
          ToastHandler.addNew(TOAST_CONTENTS.RATIO_SINGLE_EDITED(content));
          closeModal();
          resetActiveRow();
        }
      })
    }

    /** Edit log **/
    function editLog(data) {
      // The API call requires all the below properties (even if they have not been edited).
      const payload = {
        "employeeId": data.employeeId,
        "issueId": data.issueId,
        "comment": data.comment,
        "ratio": data.ratio,
        "logId": data.id,
        "excludeReason": data.excludeReason,
      }
      return store.dispatch(EDIT_LOG, payload);
    }

    /** Editable cells **/
    const editableCellHandler = ref(new EditableCellHandler());

    function updateActiveEditableCell(data) {
      editableCellHandler.value.addCell(data);
    }

    /** Reset **/
    function resetTable() {
      // Reset the selected rows.
      checkboxController.value.resetSelectedRows();

      // Inform the parent about the rows' reset.
      updateParent();
    }

    /** Modal **/
    const {activeModal, closeModal} = getModalHelpers();

    /** Reason for changing a value (ratio, export preset) **/
    const isSpecifyReasonModalDisplayed = computed(() => activeModal.value === MODAL_NAMES.SPECIFY_CHANGE_REASON(REASONS_MODAL_IDS.LOGS_TABLE));
    const {
      activeRow,
      newValueOfChange,
      typeOfChange,
      saveValueAndSpecifyReason,
      displaySpecifyReasonModal,
      resetActiveRow
    } = reasonSetup();

    function onReasonConfirmed(reason) {
      if (isChange(typeOfChange.value, TYPE_OF_CHANGE.RATIO)) {
        editRatio(newValueOfChange.value, activeRow.value, reason);
      } else if (isChange(typeOfChange.value, TYPE_OF_CHANGE.DEFAULT)) {
        editLogBasedOnReason(reason);
      }
    }

    function specifyReason(row) {
      saveValueAndSpecifyReason(null, row, TYPE_OF_CHANGE.DEFAULT); // Set the value of the change and the type of change.
      displaySpecifyReasonModal(null, () => editLogBasedOnReason(""), REASONS_MODAL_IDS.LOGS_TABLE); // Display the specify reason modal for the type of change.
    }

    function editLogBasedOnReason(reason) {
      // Set the reason.
      activeRow.value.excludeReason = reason;

      // Edit the log's reason.
      editLog(activeRow.value).then(response => {
        if (response) {
          refreshTableData();
          closeModal();
        }
      })
    }

    return {
      /** Table **/
      tableOptions,

      /** Checkboxes **/
      checkboxController,
      selectRow,
      selectAllRows,

      /** Edit comment **/
      editComment,

      /** Edit employee **/
      editEmployee,

      /** Edit ratio **/
      activeRow,
      saveEnteredRatio,

      /** Editable cells **/
      editableCellHandler,
      updateActiveEditableCell,

      /** Reset **/
      resetTable,

      /** Reason for changing a value (ratio, export preset) **/
      isSpecifyReasonModalDisplayed,
      newValueOfChange,
      typeOfChange,
      onReasonConfirmed,
      specifyReason,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.issue-text {
  color: var(--red-main);

  @include on-hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

// Ratio
.ratio-cell {
  position: relative;
}
</style>
