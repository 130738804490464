<template>
  <div v-if="unassignedLogs" class="order-details-wrapper">
    <!-- Top part of the content -->
    <div class="content-top">
      <TitleWithInfo :chip-options="chipOptions"
                     :editable="false"
                     :date-tag="`Since: ${unassignedLogs.sinceDate}`"
                     title="Unassigned logs"
                     class="left-column"/>
      <HourStatistic title="Unassigned hours" :amount="unassignedLogs.totalHours" class="unassigned-hours-statistic"/>
    </div>

    <!-- Divider line -->
    <div class="divider-line"/>

    <!-- Hour logs count -->
    <div class="hour-logs">
      <SectionTitleWithIcon :icon="require('../../assets/icons/svg/ic_time_primary.svg')"
                            hasBackground
                            :title="`Hour logs (${unassignedLogs.logCount})`"/>

      <BulkActions @logs-assigned="resetAndRefreshLogs"
                   @refresh="getProjectDetails"
                   @ratios-updated="resetAndRefreshLogs"
                   @marked-processed="resetAndRefreshLogs"
                   has-processed-option
                   :logs="selectedRows"/>
    </div>

    <!-- Search and filter bar -->
    <div class="search-with-filters">
      <SearchInputField @search="search"
                        :include-suggestions="false"
                        :result-count="searchTerm ? unassignedLogs.workLogs.length : null"
                        placeholder="Search for an hour log"
                        id="hourLogs"
                        class="search"/>
      <FiltersButton @toggle-filters="toggleFilters" :is-active="areFiltersDisplayed" class="filters-button"/>
    </div>
    <Filters v-show="areFiltersDisplayed"
             @employee-selected="onEmployeeSelection"
             @issue-selected="onIssueSelection"
             @date-selected="onDateSelection"
             @department-selected="onDepartmentSelection"
             class="filters unassigned-logs-filters"/>

    <!-- Logs table -->
    <LogsTable :logs="unassignedLogs.workLogs"
               @selected-rows="getSelectedRows"
               @refresh-table-data="resetAndRefreshLogs"
               ref="logsTable"/>

    <p v-if="unassignedLogs.noWorkLogsAvailable" class="no-results-table-text">There are no results.</p>
  </div>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import LogsTable from "@/components/partials/logs/LogsTable";
import HourStatistic from "@/components/partials/hours/HourStatistic";
import TitleWithInfo from "@/components/elements/TitleWithInfo";
import ChipOption, {CHIP_STYLES} from "@/models/ChipOption";
import SearchInputField from "@/components/partials/search/SearchInputField";
import Filters from "@/components/partials/filters/Filters";
import BulkActions from "@/components/partials/bulkActions/BulkActions";
import FiltersButton from "@/components/partials/filters/FiltersButton";
import SectionTitleWithIcon from "@/components/elements/SectionTitleWithIcon";
import {GET_UNASSIGNED_LOGS} from "../../store/project/actions";
import {initializeToast} from "@/utils/helpers/toastHelpers";
import getProject from "../../composables/projects/getProject";

export default {
  name: "UnassignedLogs",
  components: {
    SectionTitleWithIcon,
    FiltersButton,
    BulkActions,
    Filters,
    SearchInputField,
    TitleWithInfo,
    HourStatistic,
    LogsTable
  },
  setup(_, {root, emit}) {
    const store = root.$store;
    const router = root.$router;
    const projectId = root.$route.params.projectId;

    /** Title with info **/
    const chipOptions = [new ChipOption('to assign', CHIP_STYLES.YELLOW, require('../../assets/icons/svg/ic_alert.svg'))];

    /** Selected rows **/
    const selectedRows = ref([]);

    function getSelectedRows(rows) {
      selectedRows.value = rows;
    }

    /** Filters **/
    const areFiltersDisplayed = ref(false);

    function toggleFilters() {
      areFiltersDisplayed.value = !areFiltersDisplayed.value;
    }

    /** Filters - Employee **/
    const employeeIds = ref([]);

    function onEmployeeSelection(employees) {
      employeeIds.value = employees.map(employee => employee.id);
    }

    /** Filters - Employee **/
    const issueIds = ref([]);

    function onIssueSelection(issues) {
      issueIds.value = issues.map(issue => issue.id);
    }

    /** Filters - Date **/
    const dates = ref(null);

    function onDateSelection(data) {
      dates.value = data;
    }

    /** Filters - Department **/
    const departments = ref([]);

    function onDepartmentSelection(data) {
      departments.value = data.map(department => department.name);
    }

    /** Search **/
    const searchTerm = ref('');

    function search(text) {
      searchTerm.value = text;
    }

    // Every time a filter or the search term is updated, a new search should take place.
    watch([searchTerm, employeeIds, issueIds, dates, departments], () => {
      getUnassignedLogs();
    }, {deep: true});


    /** Get/Search unassigned logs **/
    getUnassignedLogs();
    const unassignedLogs = computed(() => store.getters.getUnassignedLogs);

    function getUnassignedLogs() {
      const searchedText = searchTerm.value;
      const employees = employeeIds.value;
      const issues = issueIds.value;
      const dateData = dates.value;
      const departmentData = departments.value;
      return store.dispatch(GET_UNASSIGNED_LOGS, {
        projectId,
        searchedText,
        employees,
        issues,
        dateData,
        departmentData
      });
    }

    /** Assign logs **/
    const logsTable = ref(null);

    /** Project's details **/
    const {getProjectDetails} = getProject();
    getProjectDetails(projectId);

    /** Processed hours **/
    function resetAndRefreshLogs() {
      // After the logs have been successfully assigned to an order, the logs table should be reset (selected rows).
      logsTable.value.resetTable();

      // The data should also be updated.
      getUnassignedLogs();
    }

    return {
      /** Title with info **/
      chipOptions,

      /** Selected rows **/
      selectedRows,
      getSelectedRows,

      /** Filters **/
      areFiltersDisplayed,
      toggleFilters,

      /** Filters - Employee **/
      onEmployeeSelection,

      /** Filters - Issue **/
      onIssueSelection,

      /** Filters - Date **/
      onDateSelection,

      /** Filters - Department **/
      onDepartmentSelection,

      /** Search **/
      searchTerm,
      search,
      getUnassignedLogs,

      /** Unassigned logs **/
      unassignedLogs,

      /** Assign logs **/
      logsTable,

      /** Project details **/
      getProjectDetails,

      /** Processed hours **/
      resetAndRefreshLogs,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.order-details-wrapper {
  text-align: left;
}

// Top
.content-top {
  display: flex;

  .left-column {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .unassigned-hours-statistic {
    background-color: var(--gray-main);
    border-radius: rem(10);
    flex-basis: rem(190);
    font-weight: bold;
    padding: rem(26);
  }
}

// Bottom
.hour-logs {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(25);
}
</style>
