var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CustomTable',{staticClass:"logs-table",attrs:{"options":_vm.tableOptions,"table-data":_vm.logs,"selected-rows":_vm.checkboxController.selectedRows,"bordered-cell":_vm.editableCellHandler.cell},scopedSlots:_vm._u([{key:"headcheckbox",fn:function(){return [_c('BasicCheckbox',{attrs:{"id":"checkbox-head","is-checkbox-checked":_vm.checkboxController.isCheckboxAllChecked(_vm.logs),"callback":function () { return _vm.selectAllRows(); }}})]},proxy:true},{key:"checkbox",fn:function(props){return [_c('BasicCheckbox',{attrs:{"id":("checkbox-" + (props.rowData.id)),"is-checkbox-checked":_vm.checkboxController.isCheckboxChecked(props.rowData.id),"callback":function () { return _vm.selectRow(props.rowData); }}})]}},{key:"date",fn:function(props){return [_vm._v(" "+_vm._s(props.rowData.date)+" ")]}},{key:"employee",fn:function(props){return [(props.rowData.employee.displayName)?_c('EmployeeEditableCell',{attrs:{"row-id":props.rowData.id,"text":props.rowData.employee.displayName,"content-id":("name-" + (props.rowData.employee.spacelessDisplayName))},on:{"save":function (ref) {
	var employeeId = ref.employeeId;

	return _vm.editEmployee(employeeId, props.rowData);
},"on-active-edit":_vm.updateActiveEditableCell,"on-inactive-edit":_vm.updateActiveEditableCell}}):_vm._e()]}},{key:"issue",fn:function(props){return [(props.rowData.issueLink)?_c('DLink',{attrs:{"text":props.rowData.issue.key,"link":props.rowData.issueLink}}):_c('span',[_vm._v(_vm._s(props.rowData.issueKey))])]}},{key:"summary",fn:function(props){return [_vm._v(" "+_vm._s(props.rowData.issue.summary)+" ")]}},{key:"comment",fn:function(props){return [(_vm.editable)?_c('EditableTableCell',{attrs:{"cell-key":"comment","row-data":props.rowData,"content-id":("comment-" + (props.rowData.id))},on:{"save":function (ref) {
	var content = ref.content;

	return _vm.editComment(content, props.rowData);
},"on-active-edit":_vm.updateActiveEditableCell,"on-inactive-edit":_vm.updateActiveEditableCell}}):_c('span',[_vm._v(_vm._s(props.rowData.comment))])]}},{key:"hours",fn:function(props){return [_vm._v(" "+_vm._s(props.rowData.hours)+" ")]}},{key:"ratio",fn:function(props){return [_c('div',{staticClass:"ratio-cell"},[(_vm.editable)?_c('EditableTableCell',{staticClass:"editable-cell-ratio",attrs:{"cell-key":"ratio","row-data":props.rowData,"content-id":("ratio-" + (props.rowData.id))},on:{"save":function (ref) {
	var content = ref.content;

	return _vm.saveEnteredRatio(content, props.rowData);
},"on-active-edit":_vm.updateActiveEditableCell,"on-inactive-edit":_vm.updateActiveEditableCell}}):_c('span',[_vm._v(_vm._s(props.rowData.ratio))]),_c('ReasonTooltip',{attrs:{"log":props.rowData},on:{"specify-reason":function () { return _vm.specifyReason(props.rowData); }}})],1)]}}])}),(_vm.isSpecifyReasonModalDisplayed)?_c('SpecifyReason',{attrs:{"type-of-change":_vm.typeOfChange,"new-value":_vm.newValueOfChange,"logs":[_vm.activeRow]},on:{"reason-confirmed":_vm.onReasonConfirmed}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }